<template>
  <div>
    <div class="table_box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="tableHeaderColor"
        :header-cell-class-name="cellClass"
        :cell-class-name="returnName"
      >
        <el-table-column type="selection" width="25"> </el-table-column>
        <el-table-column
          :index="indexMethod"
          type="index"
          label="序号"
          width="60"
          align="center"
        >
        </el-table-column>

        <el-table-column label="学生" width="170" align="center">
          <template slot-scope="scope">
            <div class="flexSc">
              <a href="" v-if="scope.row.user_pic">
                <img class="img" :src="scope.row.user_pic" alt=""
              /></a>
              <a href="">
                <img
                  v-if="scope.row.gender == 0"
                  class="img"
                  src="../../../assets/img/1633.png"
                  alt=""
                />
                <img
                  v-else
                  class="img"
                  src="../../../assets/img/1634.png"
                  alt=""
                />
              </a>
              <div>
                <div class="flexSt">
                  <a href="">
                    <div class="name">
                      {{
                        scope.row.student_name ? scope.row.student_name : "- -"
                      }}
                    </div>
                  </a>
                  <img
                    v-if="scope.row.gender == 1"
                    class="img_gender"
                    src="../../../assets/img/206.png"
                    alt=""
                  />
                  <img
                    v-else
                    class="img_gender"
                    src="../../../assets/img/207.png"
                    alt=""
                  />
                </div>
                <div>
                  班号：
                  {{ scope.row.number ? scope.row.number : "- -" }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="班号" sortable align="center" min-width="110">
          <template slot-scope="scope">
            <div style="padding-right: 16px">
              {{ scope.row.number ? scope.row.number : "- -" }}
            </div>
          </template></el-table-column
        >
        <el-table-column label="所在班级" align="center" min-width="110">
          <template slot-scope="scope">
            <div>
              {{ scope.row.class_name ? scope.row.class_name : "- -" }}
            </div>
          </template></el-table-column
        >
        <el-table-column width="80" label="状态" align="center">
          <template slot-scope="scope">
            <div>
              <el-tag v-if="scope.row.attendance_status == 1">出勤</el-tag>

              <!-- <el-tag type="danger" v-if="scope.row.attendance_status == 2"
                >请假</el-tag
              > -->
              <el-tag type="info" v-if="scope.row.attendance_status == 2"
                >缺勤</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="class_start_time"
          label="考勤时间"
          min-width="140"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.class_start_time
                  ? scope.row.class_start_time + "至" + scope.row.class_end_time
                  : "- -"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="teacher_name"
          align="center"
          label="考勤人"
          min-width="120"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.teacher_name ? scope.row.teacher_name : "- -" }}
            </div>
          </template></el-table-column
        >

        <el-table-column label="备注" align="center" min-width="100">
          <template slot-scope="scope">
            <div>
              {{ scope.row.remark ? scope.row.remark : "- -" }}
            </div>
          </template></el-table-column
        >
      </el-table>

      <div class="flexSe elpla">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getUser } from "@/utils/auth";
import { studentCourseAttendance } from "@/api/classroom";
export default {
  data() {
    return {
      tableData: [],
      pageInfo: {
        pageSize: 10,
        page: 1,
        total: 0,
      },
      teacher_id: "",
    };
  },
  computed: {
    indexMethod() {
      // 当前页数 - 1 * 每页数据条数 + 1
      return (this.pageInfo.page - 1) * this.pageInfo.pageSize + 1;
    },
  },
  mounted() {
    if (this.$route.query.val) {
      this.teacher_id = getUser().id;
      this.Info = JSON.parse(this.$route.query.val);
      let val = this.Info;
      console.log(val);
      this.Info.showtime = val.date + val.time;
      this.Info.course_typeName =
        val.course_type == 1 ? "常规体育课" : "大课间";
      this.handleGetstudentCourseAttendance();
    }
  },
  methods: {
    handleSelectionChange() {},
    async handleGetstudentCourseAttendance() {
      let data = {
        start_time: this.Info.class_start_time,
        end_time: this.Info.class_end_time,
        attendance_status: -1,
        name: "",
        course_type: this.Info.course_type,
        class_id: this.Info.class_id,
        per_page: 99999,
        page: 1,
        gender: -1,
        teacher_id: this.teacher_id,
        schedule_id: this.Info.id,
      };
      await studentCourseAttendance(data).then((res) => {
        this.tableData = res.data.data;
      });
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
    cellClass() {
      return "disableSelection";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "16px",
        color: "#333333",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F7F7F7",
      };
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.handleGetstudentCourseAttendance();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.handleGetstudentCourseAttendance();
    },
  },
};
</script>
<style lang="less" scoped>
.table_box {
  padding: 30px 25px;
  box-sizing: border-box;
  .img {
    width: 40px;
    height: 40px;
    margin-right: 14px;
  }
}
.elpla {
  margin-top: 30px;
}
</style>
